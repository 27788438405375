import React from 'react';
import SEO from '../components/seo';
import Helmet from 'react-helmet';

import '../styles/main.css';

export default () => (
	<main>
		<SEO title="Steelhaus | Steel stair design and construction in Barrie, Ontario" />
		<Helmet>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicon-16x16.png"
			/>
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
			<meta name="msapplication-TileColor" content="#2b5797" />
			<meta name="theme-color" content="#ffffff" />
			<meta name="og:image" content="https://www.steelhaus.co/share.png" />
		</Helmet>
		<div className="container">
			<img
				className="logo"
				src="/images/Steelhaus_logo_reverse.svg"
				alt="Steelhaus logo"
			/>
			<p>Now Open</p>
		</div>
		<footer>
			<a href="https://www.instagram.com/steelhaus_inc/" target="_blank">
				@steelhaus_inc
			</a>
			|<a href="mailto:info@steelhaus.co">info@steelhaus.co</a>
			<span className="hide-big">
				<br />
			</span>
			<span className="hide-small">|</span>
			<a href="tel:1-705-733-9292">705.733.9292</a>|
			<a href="https://goo.gl/maps/eXtLXd9KBnPyj9Gm6" target="_blank">
				20 Mills Road, Barrie, ON
			</a>
		</footer>
	</main>
);
